<script
  setup
  lang="ts"
  generic="T extends IRentIncreaseTableForm = IRentIncreaseTableForm"
>
import type { IRentIncreaseTableForm } from './types'
import { useForm } from './composables'
import { type ILeaseRegisterSettings, IncreaseTableMethod } from '@register'
import { formatRentIncreaseValue } from './utils'
import { getLeaseDateFormat } from '~/utils/getLeaseDateFormat'

const props = withDefaults(
  defineProps<{
    isOption?: boolean
    isManager?: boolean
    isFirstItem?: boolean
    commencementDate: string | Date
    expiryDate: string | Date
    rentIncrease?: T | null | undefined
    disabledDates?: string[]
    settings?: ILeaseRegisterSettings
  }>(),
  {
    isOption: false,
    isManager: false,
    isFirstItem: false,
    rentIncrease: undefined,
    disabledDates: undefined,
    settings: undefined,
  },
)
const emit = defineEmits<{
  (event: 'close'): void
  (event: 'confirm', value: T): void
}>()

const dayjs = useDayjs()
const { dateFormat: divisionDateFormat } = useDivisions()
const leaseDateFormat = computed(() =>
  getLeaseDateFormat({ settings: props.settings }),
)

// FORM
const { form, options, onSubmit, initialFormValue } = useForm(toRefs(props))
const formValue = ref<IRentIncreaseTableForm>(
  initialFormValue() as IRentIncreaseTableForm,
)

const toDayjs = (date: string | Date | null) =>
  props.isManager ? dayjs.utc(date) : dayjs(date)
const minReviewDate = computed(() =>
  props.isManager && props.commencementDate
    ? toDayjs(props.commencementDate).add(1, 'day').toISOString()
    : undefined,
)
const maxReviewDate = computed(() =>
  props.isManager && props.expiryDate
    ? toDayjs(props.expiryDate).subtract(1, 'day').toISOString()
    : undefined,
)
const showApplyToAll = computed(
  () =>
    props.isManager &&
    !props.rentIncrease &&
    props.isFirstItem &&
    ![IncreaseTableMethod.FIXED_AMOUNT, IncreaseTableMethod.MARKET].includes(
      formValue.value.method,
    ),
)

// ALERT
const isBeforeCommencement = (date: string | undefined) => {
  if (!date || !props.commencementDate) return false
  const formatedDate = toDayjs(
    props.commencementDate,
    !props.isManager ? divisionDateFormat.value : undefined,
  )
  return toDayjs(date).isBefore(formatedDate)
}

const isAfterExpiry = (date: string | undefined) => {
  if (!date || !props.expiryDate) return false
  const formatedDate = toDayjs(
    props.expiryDate,
    !props.isManager ? divisionDateFormat.value : undefined,
  )
  return toDayjs(date).isAfter(formatedDate)
}

// MODAL
const modalTitle = computed(() => {
  let title = props.rentIncrease ? 'Update rental review' : 'Add rental review'

  if (props.isOption) {
    title += ` - ${toDayjs(
      props.rentIncrease!.date,
      props.isManager ? undefined : divisionDateFormat.value,
    ).format(leaseDateFormat.value)}`
  }

  return title
})
const modalConfirmButton = computed(() => {
  if (isManagerWithoutExpiryDate.value) return 'OK'
  return props.rentIncrease ? 'Update rental review' : 'Add rental review'
})

// UTILS
const hasCollarCap = (method: IncreaseTableMethod | undefined) =>
  method === IncreaseTableMethod.CPI ||
  method === IncreaseTableMethod.CPI_PLUS ||
  method === IncreaseTableMethod.MARKET

const showCPICollarCap = (method: IncreaseTableMethod | undefined) =>
  method === IncreaseTableMethod.CPI || method === IncreaseTableMethod.CPI_PLUS

const showGreaterOf = (value: IRentIncreaseTableForm | undefined) =>
  value?._greaterOf && !value?._lesserOf && !value?._collarCap

const showLesserOf = (value: IRentIncreaseTableForm | undefined) =>
  value?._lesserOf && !value?._greaterOf && !value?._collarCap

const onChange = (key: '_collarCap' | '_greaterOf' | '_lesserOf') => {
  formValue.value._collarCap = false
  formValue.value._greaterOf = false
  formValue.value._lesserOf = false
  formValue.value[key] = true
}

//Localization
const isManagerWithoutExpiryDate = computed(
  () => props.isManager && !props.expiryDate,
)

const applyAllText = computed(() => formatRentIncreaseValue(formValue.value))
</script>

<template>
  <ModalBase
    :title="modalTitle"
    :primary-button-text="modalConfirmButton"
    :secondary-button-text="isManagerWithoutExpiryDate ? null : 'Cancel'"
    modal-class="max-w-[600px] bg-gray-850 border-gray-750 dark:bg-gray-850 dark:border-gray-800"
    @confirm="isManagerWithoutExpiryDate ? $emit('close') : form?.node.submit()"
    @close="$emit('close')"
  >
    <div v-if="isManager && !props.expiryDate">
      <h2 class="text-lg">
        It's not possible to add a rent review without expiry date.
      </h2>
      <p class="mt-2">Please, add the lease dates first.</p>
    </div>

    <FormKit
      v-else
      v-slot="{ value }: { value: IRentIncreaseTableForm }"
      ref="form"
      v-model="formValue"
      type="form"
      name="RentReviewTableValue"
      :actions="false"
      form-class="rounded-lg bg-gray-850"
      @submit="onSubmit"
    >
      <div class="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
        <!-- DATE -->
        <Tooltip
          :content="
            isFirstItem && isManager
              ? `Change the date if rent review is different from lease anniversary`
              : undefined
          "
          placement="top"
          class="bg-warning text-xs text-gray-950"
        >
          <FormKit
            type="datepicker"
            name="date"
            label="Review date"
            validation="required"
            :min-date="minReviewDate"
            :max-date="maxReviewDate"
            :disabled-dates="disabledDates"
            :utc="isManager ? 'preserve' : undefined"
          />
        </Tooltip>

        <!-- METHOD -->
        <FormKit
          type="listbox"
          name="method"
          label="Method"
          :options="options"
          :searchable="true"
          :allow-empty="false"
          validation="required"
        />

        <!-- Formula -->
        <FormKit
          v-if="value?.method === IncreaseTableMethod.FORMULA"
          key="formula"
          type="text"
          name="formula"
          label="Formula"
          outer-class="col-span-full"
          validation="required"
        >
          <template #prefix>
            <span
              class="flex h-10 items-center rounded-l-[5px] bg-gray-700 px-2.5 pt-px text-xs"
            >
              <Icon name="amount-squre" filled />
            </span>
          </template>
        </FormKit>

        <!-- FIXED PERCENT -->
        <FormKit
          v-if="value?.method === IncreaseTableMethod.FIXED_PERCENT"
          key="fixedPercent"
          type="number"
          name="fixedPercent"
          label="Fixed Percent"
          outer-class="col-span-full"
          validation="required"
        >
          <template #suffix>
            <span
              class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
            >
              %
            </span>
          </template>
        </FormKit>

        <!-- FIXED AMOUNT -->
        <FormKit
          v-if="value?.method === IncreaseTableMethod.FIXED_AMOUNT"
          key="fixedAmount"
          type="number"
          step="1"
          min="0"
          validation="min:0"
          name="fixedAmount"
          label="Fixed Amount"
          outer-class="col-span-full"
        >
          <!-- :mask="settings.formatting?.currency.mask ?? `{#,##|repeat}#`"
          show-mask="false"
          unmask-value="true"
          allow-incomplete -->
          <template #prefix>
            <span
              class="flex h-10 w-10 items-center justify-center rounded-l-[5px] bg-gray-700 px-2.5 pt-px text-xs"
            >
              {{ settings?.currency?.symbol ?? '$' }}
            </span>
          </template>

          <template #suffix>
            <span
              v-if="settings?.currency?.id"
              class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
            >
              {{ settings?.currency.id }}
            </span>
          </template>
        </FormKit>

        <!-- CPI PLUS - CPI PERCENT -->
        <FormKit
          v-if="value?.method === IncreaseTableMethod.CPI_PLUS"
          key="cpiPercent"
          type="number"
          step="1"
          min="0"
          validation="required|min:0"
          name="cpiPercent"
          label="CPI Plus"
          outer-class="col-span-full"
        >
          <template #suffix>
            <span
              class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
            >
              %
            </span>
          </template>
        </FormKit>

        <!-- Checkboxes: Collar & Cap | Greater Of | Lesser Of -->
        <template v-if="hasCollarCap(value?.method)">
          <div
            v-auto-animate
            class="col-span-full grid grid-cols-1 gap-4 md:grid-cols-3"
          >
            <!-- Checkbox: Collar & Cap -->
            <FormKit
              key="_collarCap"
              name="_collarCap"
              type="checkbox"
              label="Collar & Cap"
              outer-class="w-full"
              @change="onChange('_collarCap')"
            />

            <!-- Checkbox: Greater Of -->
            <FormKit
              key="_greaterOf"
              name="_greaterOf"
              type="checkbox"
              label="Greater Of"
              outer-class="w-full"
              @change="onChange('_greaterOf')"
            />

            <!-- Checkbox: Lesser Of -->
            <FormKit
              key="_lesserOf"
              name="_lesserOf"
              type="checkbox"
              label="Lesser Of"
              outer-class="w-full"
              @change="onChange('_lesserOf')"
            />
          </div>
        </template>

        <!-- Collar & Cap -->
        <div
          v-if="value?._collarCap && !value?._greaterOf && !value?._lesserOf"
          v-auto-animate
          class="col-span-full grid grid-cols-1 gap-4 md:grid-cols-2"
        >
          <!-- CPI | CPI PLUS - Cap -->
          <FormKit
            v-if="showCPICollarCap(value?.method)"
            key="cpiCollar"
            type="number"
            step="1"
            min="0"
            validation="min:0"
            name="cpiCollar"
            label="CPI Collar"
          >
            <template #suffix>
              <span
                class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
              >
                %
              </span>
            </template>
          </FormKit>

          <!-- CPI | CPI PLUS - Collar -->
          <FormKit
            v-if="showCPICollarCap(value?.method)"
            key="cpiCap"
            type="number"
            step="1"
            min="0"
            validation="min:0"
            name="cpiCap"
            label="CPI Cap"
          >
            <template #suffix>
              <span
                class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
              >
                %
              </span>
            </template>
          </FormKit>

          <!-- Market - Collar -->
          <FormKit
            v-if="value?.method === IncreaseTableMethod.MARKET"
            key="marketReviewCollar"
            type="number"
            step="1"
            min="0"
            validation="min:0"
            name="marketReviewCollar"
            label="Market Review Collar"
          >
            <template #suffix>
              <span
                class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
              >
                %
              </span>
            </template>
          </FormKit>

          <!-- Market - Cap -->
          <FormKit
            v-if="value?.method === IncreaseTableMethod.MARKET"
            key="marketReviewCap"
            type="number"
            step="1"
            min="0"
            validation="min:0"
            name="marketReviewCap"
            label="Market Review Cap"
          >
            <template #suffix>
              <span
                class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
              >
                %
              </span>
            </template>
          </FormKit>
        </div>

        <!-- Greater Of -->
        <FormKit
          v-if="showGreaterOf(value)"
          key="greaterOf"
          type="number"
          step="1"
          min="0"
          validation="required|min:0"
          name="greaterOf"
          label="Greater Of"
        >
          <template #suffix>
            <span
              class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
            >
              %
            </span>
          </template>
        </FormKit>

        <!-- Lesser Of -->
        <FormKit
          v-if="showLesserOf(value)"
          key="lesserOf"
          type="number"
          step="1"
          min="0"
          validation="required|min:0"
          name="lesserOf"
          label="Lesser Of"
        >
          <template #suffix>
            <span
              class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
            >
              %
            </span>
          </template>
        </FormKit>

        <!-- MARKET - MARKET REVIEW DATE -->
        <div class="col-span-full flex gap-4">
          <FormKit
            v-if="value?.method === IncreaseTableMethod.MARKET"
            key="marketReviewDate"
            type="datepicker"
            name="marketReviewDate"
            label="Market Settlement Date"
            outer-class="grow flex basis-0 "
            wrapper-class="w-full"
            validation="required"
          />

          <FormKit
            v-if="value?.method === IncreaseTableMethod.MARKET && isManager"
            key="marketReviewAmount"
            type="number"
            name="marketReviewAmount"
            label="Market Rent Review Amount"
            step="1"
            min="0"
            validation="min:0"
            outer-class="grow flex basis-0 flex-col"
            wrapper-class="w-full"
          >
            <template #prefix>
              <span
                class="flex h-10 w-10 items-center justify-center rounded-l-[5px] bg-gray-700 px-2.5 pt-px text-xs"
              >
                {{ settings?.currency?.symbol ?? '$' }}
              </span>
            </template>

            <template #suffix>
              <span
                v-if="settings?.currency?.id"
                class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
              >
                {{ settings?.currency.id }}
              </span>
            </template>
          </FormKit>
        </div>

        <!-- Apply to all -->
        <template v-if="showApplyToAll && applyAllText !== '-'">
          <span class="formkit-label inline-flex items-center gap-1 text-sm">
            <FormKit type="toggle" name="duplicate" />
            Set all rent increases to
            <span class="font-bold text-gray-100">
              {{ applyAllText }}
            </span>
          </span>
        </template>
      </div>

      <template v-if="!isManager && !isOption">
        <div
          v-if="isBeforeCommencement(value?.date)"
          class="mt-4 rounded-lg border border-yellow-500 bg-yellow-500/10 p-4 text-yellow-500"
        >
          Date is before Commencement Date
        </div>
        <div
          v-if="isAfterExpiry(value?.date)"
          class="mt-4 rounded-lg border border-yellow-500 bg-yellow-500/10 p-4 text-yellow-500"
        >
          Date is after Expiry Date
        </div>
      </template>
    </FormKit>
  </ModalBase>
</template>
